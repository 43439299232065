import { IPackage, IPackageQuery, IWareHouse, IStatus, IProductSuggestForPackage, IPackageCreateRequest } from "@domain/version2/Package"
import { IDetailPackage } from "@domain/version2/PackageDetail"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export class PackageApi {
    static getPackages(params: IPackageQuery): Promise<AxiosResponse<IPackage[]>> {
        return apiClient.get(`packages`, {
            params,
        })
    }
    static getWareHouseSuggest(): Promise<AxiosResponse<IWareHouse[]>> {
        return apiClient.get(`warehouses/autocomplete`)
    }
    static getStatusSuggest(): Promise<AxiosResponse<IStatus[]>> {
        return apiClient.get(`packages/statuses/autocomplete`)
    }
    static getPackageDetail(code: string): Promise<AxiosResponse<IDetailPackage>> {
        return apiClient.get(`packages/${code}`)
    }
    static updatePackage(id: number) {
        return apiClient.patch(`packages/${id}/cancel`)
    }
    static getProductSuggestForPackage(params: { keyword: string }): Promise<AxiosResponse<IProductSuggestForPackage[]>> {
        return apiClient.get(`packages/products/autocomplete`, { params })
    }
    static createPackage(payload: IPackageCreateRequest) {
        return apiClient.post(`packages`, payload)
    }
    static importPackageSku(file: any): Promise<any> {
        const formData: any = new FormData()
        formData.append("file", file)
        return apiClient.post(`packages/skus/import`, formData)
    }
}
