import React from "react"
import { Card, Checkbox, Col, message, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeVisibleFormReasonCancel, fetchUpdateOrderPaid, fetchUpdateOrderStatus, getOrderDetail, fetchUpdateOrderFinancialStatus } from "@reducer/version2/order-detail.reducer"
import { formatDateFull } from "@util/Common"
import { STATUS_CAN_UPDATE } from "@config/constant"
import { callGetSubOrderStatus } from "@reducer/version2/categories.reducer"

export const OrderDetailHeader: React.FC = () => {
    const dispatch = useAppDispatch()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loadingUpdateStatus = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderStatus)
    const orderStatuses = useAppSelector((state) => state.orderVersion2.statuses)    
    const orderFinancialStatuses = useAppSelector((state) => state.orderDetailVersion2.financialStatuses)
    const loadingUpdateOrderFinancialStatus = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderFinancialStatus)

    const onChangeStatus = (status: string) => {
        if (status === "CANCELLED") {
            dispatch(callGetSubOrderStatus(status))
            dispatch(changeVisibleFormReasonCancel(true))
        }else{
            dispatch(
                fetchUpdateOrderStatus({
                    code: detailOrder?.code || "",
                    body: {
                        status: status || "",
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    const onChangeFinancialStatus = (status: string) => {
        if (status) {
            dispatch(
                fetchUpdateOrderFinancialStatus({
                    code: detailOrder?.code || "",
                    body: {
                        financial: status || "",
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    const onUpdatePaid = (isPaid: boolean) => {
        dispatch(
            fetchUpdateOrderPaid({
                code: detailOrder?.code || "",
                body: {
                    isPaid,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(getOrderDetail(detailOrder?.code || ""))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    return (
        <>
            <Card className="space-layout custom-bottom">
                <Row gutter={[15, 15]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order.code")}</p>
                        <div className="font-medium">{detailOrder?.code}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order.campaign")}</p>
                        <div className="font-medium">{detailOrder?.campaign?.name || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.ads")}</p>
                        <div className="font-medium">{detailOrder?.ads?.name || ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.due_date")}</p>
                        <div className="font-medium">{detailOrder?.deliveryDate ? formatDateFull(detailOrder?.deliveryDate) : "---"}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.status")}</p>
                        {SecurityService.can(ORDER_SCOPE.ORDER_STATUS_UPDATE) && STATUS_CAN_UPDATE.includes(detailOrder?.status || "") ? (
                            <Select
                                loading={loadingUpdateStatus}
                                value={detailOrder?.status}
                                className="block w-11/12"
                                onChange={(e) => {
                                    onChangeStatus(e)
                                }}>
                                {orderStatuses.map((x: any, index: number) => (
                                    <Select.Option
                                        key={x.code}
                                        value={x.code}
                                        disabled={
                                            index < orderStatuses.findIndex((x: any) => x.code === detailOrder?.status) ||
                                            ![...STATUS_CAN_UPDATE, "CANCELLED"].includes(x.code)
                                        }>
                                        {trans(`order_status.${x?.code}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <div className="font-medium">{trans(`order_status.${detailOrder?.status}`)}</div>
                        )}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.created_time")}</p>
                        <div className="font-medium">{detailOrder?.createdAt ? formatDateFull(detailOrder?.createdAt) : ""}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.created_by")}</p>
                        <div className="font-medium">{detailOrder?.createdBy}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <Checkbox
                            onChange={(e) => {
                                onUpdatePaid(e.target.checked)
                            }}
                            className="mt-3"
                            disabled={!SecurityService.can(ORDER_SCOPE.ORDER_PAID_UPDATE) || !STATUS_CAN_UPDATE.includes(detailOrder?.status || "")}
                            checked={detailOrder?.isPaid}>
                            {trans("order_detail.paid")}
                        </Checkbox>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.financial_status")}</p>
                        {SecurityService.can(ORDER_SCOPE.ORDER_FINANCIAL_STATUS_UPDATE) ? (
                            <Select
                                loading={loadingUpdateOrderFinancialStatus}
                                value={detailOrder?.financial}
                                className="block w-11/12"
                                onChange={(e) => {
                                    onChangeFinancialStatus(e)
                                }}>
                                {orderFinancialStatuses.map((item: any) => (
                                    <Select.Option
                                        key={item.code}
                                        value={item.code}>
                                        {trans(`order_detail.financial_status_${item?.code}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <div className="font-medium">{trans(`order_detail.financial_status_${detailOrder?.financial}`)}</div>
                        )}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("order_detail.tracking_number")}</p>
                        <div className="font-medium">{detailOrder?.trackingNumber || "---"}</div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}>
                        <p className="m-0">{trans("vendor.marketer")}</p>
                        <div className="font-medium">{detailOrder?.campaign?.createdBy || ""}</div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
