import { IComboCategories, IContactEventStatus, IContactStatus, ISubReasonStatus } from "@domain/version2/Categories"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class CategoriesApi {
    static getListContactStatus(): Promise<AxiosResponse<IContactStatus[]>> {
        return apiClient.get("categories/contact-statuses")
    }
    static getListContactEventStatus(): Promise<AxiosResponse<IContactEventStatus[]>> {
        return apiClient.get("categories/contact-event-statuses")
    }
    static getListOrderStatus() {
        return apiClient.get("categories/order-statuses")
    }
    static getListCurrencies() {
        return apiClient.get("currencies")
    }
    static getSubOrderStatus(parentCode: string): Promise<AxiosResponse<ISubReasonStatus[]>> {
        return apiClient.get(`categories/order-statuses-by-parent/${parentCode}`)
    }

    static getListSkuComboCategories(params: { keyword: string }): Promise<AxiosResponse<IComboCategories[]>> {
        return apiClient.get("combo-categories/autocomplete", { params })
    }
}
