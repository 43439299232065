import { PackageApi } from "@api/version2/PackageApi"
import { PAGINATION } from "@config/constant"
import { IPackage, IPackageQuery, IWareHouse, IStatus, IProductSuggestForPackage, IPackageCreateRequest } from "@domain/version2/Package"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    packages: IPackage[]
    loadingList: boolean
    pagination: {
        currentPage: number
        pageSize: number
        total: number
        totalPage: number
    },
    wareHouses: IWareHouse[]
    statuses: IStatus[]
    productSuggestForPackage: IProductSuggestForPackage[]
    loadingProducts: boolean
    loadingCreate: boolean
}
const initState: State = {
    packages: [],
    loadingList: false,
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    wareHouses: [],
    statuses: [],
    productSuggestForPackage: [],
    loadingProducts: false,
    loadingCreate: false
}
export const callGetPackages = createAsyncThunk("PACKAGE.GET_LIST", async (params: IPackageQuery, thunkApi) => {
    try {
        return await PackageApi.getPackages(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetWareHouses = createAsyncThunk("PACKAGE.GET_WAREHOUSES", async (_, thunkApi) => {
    try {
        return await PackageApi.getWareHouseSuggest()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetStatuses = createAsyncThunk("PACKAGE.GET_STATUSES", async (_, thunkApi) => {
    try {
        return await PackageApi.getStatusSuggest()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetProductSuggestForPackage = createAsyncThunk("PACKAGE.GET_PRODUCT", async (params: {keyword: string}, thunkApi) => {
    try {
        return await PackageApi.getProductSuggestForPackage(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callCreatePackage = createAsyncThunk("PACKAGE.CREATE_PACKAGE", async (payload: IPackageCreateRequest, thunkApi) => {
    try {
        return await PackageApi.createPackage(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const packageReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetPackages.pending, (state) => {
            state.loadingList = true
            state.packages = []
        })

        .addCase(callGetPackages.fulfilled, (state, { payload }) => {
            state.packages = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callGetPackages.rejected, (state) => {
            state.packages = []
            state.loadingList = false
        })
    builder
        .addCase(callGetWareHouses.pending, (state) => {
            state.wareHouses = []
        })
        .addCase(callGetWareHouses.fulfilled, (state, { payload }) => {
            state.wareHouses = payload.data ? payload.data : []
        })
        .addCase(callGetWareHouses.rejected, (state) => {
            state.wareHouses = []
        })
    builder
        .addCase(callGetStatuses.pending, (state) => {
            state.statuses = []
        })
        .addCase(callGetStatuses.fulfilled, (state, { payload }) => {
            state.statuses = payload.data ? payload.data : []
        })
        .addCase(callGetStatuses.rejected, (state) => {
            state.statuses = []
        })
    builder
        .addCase(callGetProductSuggestForPackage.pending, (state) => {
            state.loadingProducts = true
            state.productSuggestForPackage = []
        })
        .addCase(callGetProductSuggestForPackage.fulfilled, (state, { payload }) => {
            state.loadingProducts = false
            state.productSuggestForPackage = payload.data ? payload.data : []
        })
        .addCase(callGetProductSuggestForPackage.rejected, (state) => {
            state.loadingProducts = false
            state.productSuggestForPackage = []
        })
    builder
        .addCase(callCreatePackage.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreatePackage.fulfilled, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreatePackage.rejected, (state) => {
            state.loadingCreate = false
        })
})
