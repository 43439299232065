import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changePageTitleAction } from "@reducer/common.reducer"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Button, Col, Form, message, Row, Space } from "antd"
import { useNavigate } from "react-router-dom"
import { OrderInfo } from "./OrderInfo"
import { Products } from "./Products"
import { Customer } from "./Customer"
import { callGetCourierVendor } from "@reducer/version2/courier-setting.reducer"
import { callVendorDetail } from "@reducer/version2/vendor-detail.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { getProvinceSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { ICreateOrderRequest } from "@domain/version2/Order"
import { callCreateOrder } from "@reducer/version2/order.reducer"
import { isEmpty } from "lodash"

export const CreateOrder: React.FC = () => {
    const [formRef] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const loadingCreateOrder = useAppSelector((state) => state.orderVersion2.loadingCreateOrder)
    const [isExpand, setIsExpand] = useState(false)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("order.create_order")))
        dispatch(callGetCourierVendor())
        dispatch(callVendorDetail())
    }, [dispatch])

    const countryCode = useAppSelector((state) => state.vendorDetail.detailVendor.countryCode)

    useEffect(() => {
        if (countryCode) {
            dispatch(getProvinceSuggest(countryCode))
        }
    }, [countryCode])

    const handleSubmitForm = (values: any) => {
        const data: ICreateOrderRequest = {
            code: values?.code || undefined,
            customerName: values?.customerName || undefined,
            customerPhone: values?.customerPhone || undefined,
            trackingNumber: values?.trackingNumber || undefined,
            courierService: values?.courierService || undefined,
            countryCode: countryCode || undefined,
            provinceCode: values?.provinceCode || undefined,
            cityCode: values?.cityCode || undefined,
            districtCode: values?.districtCode || undefined,
            ward: values?.wardCode || undefined,
            address: values?.address || undefined,
            postalCode: values?.postalCode || undefined,
            totalPayment: values?.totalPayment || undefined,
            deliveryFee: values?.deliveryFee || undefined,
            orderItems: !isEmpty(values?.tableProducts)
                ? values?.tableProducts.map((el: any) => {
                      const product = {
                          sku: el?.sku,
                          quantity: el?.quantity,
                          price: el?.unitPrice,
                      }
                      return { ...product }
                  })
                : undefined,
            note: values?.note || undefined,
        }

        dispatch(callCreateOrder(data)).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                formRef.resetFields()
                dispatch(resetDataDistrict([]))
                dispatch(resetDataCity([]))
                dispatch(resetDataArea([]))
                navigate("/order")
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <DefaultLayout loading={false}>
            <HeaderAntBreadCrumb
                breadCrumbs={[
                    {
                        name: "sidebar.dashboard",
                        link: "/",
                    },
                    {
                        name: "order_detail.header",
                        link: "/order",
                    },
                    {
                        name: "order.create_order",
                        link: "",
                    },
                ]}
            />
            <div className="mx-3">
                <Form
                    form={formRef}
                    layout="vertical"
                    // labelCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 8 } }}
                    // wrapperCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 16 } }}
                    labelAlign="left"
                    onFinish={handleSubmitForm}>
                    <Row
                        justify="end"
                        gutter={15}
                        className="mx-3">
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: isExpand ? 24 : 14 }}>
                            <OrderInfo />
                            <Products
                                formRef={formRef}
                                setIsExpand={() => {
                                    setIsExpand((old) => !old)
                                }}
                            />
                        </Col>
                        <Col
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: isExpand ? 24 : 10 }}>
                            <Customer formRef={formRef} />
                        </Col>

                        <Space className="mr-2 mt-6">
                            <Button
                                type="default"
                                loading={loadingCreateOrder}
                                onClick={() => {
                                    navigate("/order")
                                }}>
                                {trans("contact.cancel")}
                            </Button>
                            <Button
                                type="primary"
                                loading={loadingCreateOrder}
                                htmlType="submit">
                                {trans("order.create_order")}
                            </Button>
                        </Space>
                    </Row>
                </Form>
            </div>
        </DefaultLayout>
    )
}
