import { createAction, createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit"
import OrderApi from '@api/version2/OrderApi'
import { IDetailOrder, IOrderRequest, ItemOrderRequest, IOrderFinancialStatus } from "@domain/version2/OrderDetail"

interface State {
    loadingDetail: boolean
    orderDetail: IDetailOrder
    loadingUpdateOrder: boolean
    isUpdateNote: boolean
    isUpdateShippingAddress: boolean
    loadingUpdateOrderItem: boolean
    loadingUpdateOrderStatus: boolean
    dataUpdateOrderItem: ItemOrderRequest[]
    dataOrderUpdate: IOrderRequest
    loadingUpdatePaid: boolean
    visibleFormReasonCancel: boolean
    financialStatuses: Array<IOrderFinancialStatus>
    loadingUpdateOrderFinancialStatus:  boolean
}

const initState: State = {
    loadingDetail: false,
    orderDetail: {},
    loadingUpdateOrder: false,
    isUpdateNote: false,
    isUpdateShippingAddress: false,
    loadingUpdateOrderItem: false,
    loadingUpdateOrderStatus: false,
    dataUpdateOrderItem: [],
    dataOrderUpdate: {},
    loadingUpdatePaid: false,
    visibleFormReasonCancel: false,
    financialStatuses: [],
    loadingUpdateOrderFinancialStatus: false
}

export const getOrderDetail = createAsyncThunk("ORDER_DETAIL", async (code: string, thunkApi) => {
    try {
        return await OrderApi.getOrderDetail(code)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const fetchUpdateOrder = createAsyncThunk("ORDER_DETAIL.UPDATE_ORDER", async (payload: { code: string; body: IOrderRequest }, thunkApi) => {
    try {
        return await OrderApi.updateOrder(payload.code, payload.body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const fetchUpdateOrderItem = createAsyncThunk(
    "ORDER_DETAIL.UPDATE_ORDER_ITEM",
    async (payload: { code: string; body: {orderItems:ItemOrderRequest[], orderCombos: ItemOrderRequest[]} }, thunkApi) => {
        try {
            return await OrderApi.updateOrderItem(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const fetchUpdateOrderStatus = createAsyncThunk(
    "ORDER_DETAIL.UPDATE_STATUS",
    async (payload: { code: string; body: { status: string } }, thunkApi) => {
        try {
            return await OrderApi.updateOrderStatus(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const fetchUpdateOrderPaid = createAsyncThunk(
    "ORDER_DETAIL.UPDATE_PAID",
    async (payload: { code: string; body: { isPaid: boolean } }, thunkApi) => {
        try {
            return await OrderApi.updateOrderPaid(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)
export const fetchOrderFinancialStatuses = createAsyncThunk("ORDER.FETCH_ORDER__FINANCIAL_STATUS", async (_, thunkApi) => {
    try {
        return await OrderApi.getOrderFinancialStatuses()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const fetchUpdateOrderFinancialStatus = createAsyncThunk(
    "ORDER_DETAIL.UPDATE_FINANCIAL_STATUS",
    async (payload: { code: string; body: { financial: string } }, thunkApi) => {
        try {
            return await OrderApi.updateOrderFinancialStatus(payload.code, payload.body)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const changeEditNoteOrder = createAction<boolean>("ORDER_DETAIL.CHANGE_EDIT_NOTE_ORDER")

export const changeEditShippingAddress = createAction<boolean>("ORDER_DETAIL.CHANGE_EDIT_SHIPPING_ADDRESS")

export const changeDataOrderUpdate = createAction<IOrderRequest>("ORDER_DETAIL.UPDATE_DATA")
export const changeDataUpdateOrderItem = createAction<ItemOrderRequest[] | undefined>("ORDER_DETAIL.UPDATE_DETAIL_DATA")

export const changeVisibleFormReasonCancel = createAction<boolean>("ORDER_DETAIL.FORM_REASON_CANCEL")

export const orderDetailReducer = createReducer(initState, (builder) => {
    builder
        .addCase(getOrderDetail.pending, (state) => {
            state.loadingDetail = true
            state.orderDetail = {}
        })
        .addCase(getOrderDetail.fulfilled, (state, { payload }) => {
            state.orderDetail = payload?.data
            state.loadingDetail = false
        })
        .addCase(getOrderDetail.rejected, (state) => {
            state.loadingDetail = false
        })
    builder
        .addCase(fetchUpdateOrder.pending, (state) => {
            state.loadingUpdateOrder = true
        })
        .addCase(fetchUpdateOrder.fulfilled, (state) => {
            state.loadingUpdateOrder = false
        })
        .addCase(fetchUpdateOrder.rejected, (state) => {
            state.loadingUpdateOrder = false
        })
    builder
        .addCase(fetchUpdateOrderItem.pending, (state) => {
            state.loadingUpdateOrderItem = true
        })
        .addCase(fetchUpdateOrderItem.fulfilled, (state) => {
            state.loadingUpdateOrderItem = false
        })
        .addCase(fetchUpdateOrderItem.rejected, (state) => {
            state.loadingUpdateOrderItem = false
        })
    builder
        .addCase(fetchUpdateOrderStatus.pending, (state) => {
            state.loadingUpdateOrderStatus = true
        })
        .addCase(fetchUpdateOrderStatus.fulfilled, (state) => {
            state.loadingUpdateOrderStatus = false
        })
        .addCase(fetchUpdateOrderStatus.rejected, (state) => {
            state.loadingUpdateOrderStatus = false
        })
    builder
        .addCase(fetchUpdateOrderPaid.pending, (state) => {
            state.loadingUpdatePaid = true
        })
        .addCase(fetchUpdateOrderPaid.fulfilled, (state) => {
            state.loadingUpdatePaid = false
        })
        .addCase(fetchUpdateOrderPaid.rejected, (state) => {
            state.loadingUpdatePaid = false
        })

    
    builder.addCase(changeEditNoteOrder, (state, action: PayloadAction<boolean>) => {
        state.isUpdateNote = action.payload
    })
    builder.addCase(changeEditShippingAddress, (state, action: PayloadAction<boolean>) => {
        state.isUpdateShippingAddress = action.payload
    })
    builder.addCase(changeDataUpdateOrderItem, (state, action: PayloadAction<ItemOrderRequest[] | undefined>) => {
        state.dataUpdateOrderItem = action?.payload || []
    })
    builder.addCase(changeDataOrderUpdate, (state, action: PayloadAction<IOrderRequest>) => {
        state.dataOrderUpdate = {
            ...state.dataOrderUpdate,
            ...action.payload
        }
    })
    builder.addCase(changeVisibleFormReasonCancel, (state, action) => {
        state.visibleFormReasonCancel = action.payload
    })
    builder
        .addCase(fetchOrderFinancialStatuses.pending, (state) => {
            state.financialStatuses = []
        })
        .addCase(fetchOrderFinancialStatuses.fulfilled, (state, action) => {
            state.financialStatuses = action.payload.data
        })
        .addCase(fetchOrderFinancialStatuses.rejected, (state) => {
            state.financialStatuses = []
        })
    builder
        .addCase(fetchUpdateOrderFinancialStatus.pending, (state) => {
            state.loadingUpdateOrderFinancialStatus = true
        })
        .addCase(fetchUpdateOrderFinancialStatus.fulfilled, (state) => {
            state.loadingUpdateOrderFinancialStatus = false
        })
        .addCase(fetchUpdateOrderFinancialStatus.rejected, (state) => {
            state.loadingUpdateOrderFinancialStatus = false
        })
})
