import { commonReducer } from "@reducer/common.reducer"
//V1
import { vendorDetailReducer } from "./version2/vendor-detail.reducer"
import { userReducer as userReducerVer1 } from "./version1/user.reducer"

//V2
import { userReducer as userReducerVer2 } from "./version2/user.reducer"
import { campaignReducer as campaignVer2 } from "./version2/campaign.reducer"
import { campaignDetailReducer as campaignDetailVer2 } from "./version2/campaign-detail.reducer"
import { adsReducer as adsReducerVer2 } from "./version2/ads.reducer"
import { adsDetailReducer as adsDetailReducerVer2 } from "./version2/ads-detail.reducer"
import { categoriesReducer as categoriesVer2 } from "./version2/categories.reducer"
import { countriesReducer as countriesVer2 } from "./version2/countries.reducer"
import { productReducer as productVer2 } from "./version2/product.reducer"
import { orderReducer as OrderVer2 } from "./version2/order.reducer"
import { orderDetailReducer as DetailOrderVer2 } from "./version2/order-detail.reducer"

import { contactReducer2 } from "./version2/contact.reducer"
import { contactDetailReducer2 } from "./version2/contact-detail.reducer"
import { paymentStatementReducer } from "./version2/paymentStatement.reducer"
import { currenciesReducer } from "./version2/currency.reducer"
import { paymentStatementDetailReducer } from "./version2/paymentState-detail.reducer"
import { courierSettingReducer } from "./version2/courier-setting.reducer"
import { dashboardReducer } from "./version2/dashboard.reducer"
import { sellChannelReducer } from "./version2/sell-channel.reducer"
import { inventoryReducer } from "./version2/inventory.reducer"
import { packageReducer } from "./version2/package.reducer"
import { packageDetailReducer } from "./version2/package-detail.reducer"

import { productGroupsReducer } from "./version2/product-groups.reducer"
import { skuComboReducer } from "./version2/skuCombo.reducer"

const rootReducer = {
    common: commonReducer,
    vendorDetail: vendorDetailReducer,
    usersVersion1: userReducerVer1,
    //v2
    usersVersion2: userReducerVer2,
    campaignVersion2: campaignVer2,
    campaignDetailVer2: campaignDetailVer2,
    adsVersion2: adsReducerVer2,
    adsDetailVersion2: adsDetailReducerVer2,
    categoriesVersion2: categoriesVer2,
    countriesVersion2: countriesVer2,
    productVersion2: productVer2,
    orderVersion2: OrderVer2,
    orderDetailVersion2: DetailOrderVer2,
    contact2: contactReducer2,
    contactDetail2: contactDetailReducer2,
    paymentStatement: paymentStatementReducer,
    currencies: currenciesReducer,
    paymentDetail: paymentStatementDetailReducer,
    courierSetting: courierSettingReducer,
    dashboard: dashboardReducer,
    inventory: inventoryReducer,
    package: packageReducer,
    packageDetail: packageDetailReducer,
    sellChannel: sellChannelReducer,
    productGroups: productGroupsReducer,
    skuCombo: skuComboReducer
}

export default rootReducer
