import CategoriesApi from "@api/version2/CategoriesApi"
import { IComboCategories, IContactEventStatus, IContactStatus, ICurrency, ISubReasonStatus } from "@domain/version2/Categories"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    listContactStatuses: Array<IContactStatus>
    listContactEventStatuses: Array<IContactEventStatus>
    listOrderStatuses: Array<any>
    listCurrencies: ICurrency[]
    subOrderStatuses: ISubReasonStatus[]
    listSkuComboCategories: IComboCategories[]
}
const initState: State = {
    listContactStatuses: [],
    listContactEventStatuses: [],
    listOrderStatuses: [],
    listCurrencies: [],
    subOrderStatuses: [],
    listSkuComboCategories: [],
}

export const callListContactStatuses = createAsyncThunk("CATEGORIES.GET_CONTACT_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getListContactStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callListContactEventStatuses = createAsyncThunk("CATEGORIES.GET_CALL_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getListContactEventStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callListOrderStatuses = createAsyncThunk("CATEGORIES.GET_ORDER_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getListOrderStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetListCurrencies = createAsyncThunk("CATEGORIES.GET_CURRENCIES", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getListCurrencies()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetSubOrderStatus = createAsyncThunk("CATEGORIES_GET_SUB_ORDER_STATUS", async (parentCode: string, thunkApi) => {
    try {
        return await CategoriesApi.getSubOrderStatus(parentCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetListSkuComboCategories = createAsyncThunk("CATEGORIES_GET_SKU_COMBO_CATEGORIES", async (params: { keyword: string }, thunkApi) => {
    try {
        return await CategoriesApi.getListSkuComboCategories(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const categoriesReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callListContactStatuses.pending, (state) => {
            state.listContactStatuses = []
        })
        .addCase(callListContactStatuses.fulfilled, (state, { payload }) => {
            state.listContactStatuses = payload?.data
        })
        .addCase(callListContactStatuses.rejected, (state) => {
            state.listContactStatuses = []
        })

    builder
        .addCase(callListContactEventStatuses.pending, (state) => {
            state.listContactEventStatuses = []
        })
        .addCase(callListContactEventStatuses.fulfilled, (state, { payload }) => {
            state.listContactEventStatuses = payload?.data
        })
        .addCase(callListContactEventStatuses.rejected, (state) => {
            state.listContactEventStatuses = []
        })

    builder
        .addCase(callListOrderStatuses.pending, (state) => {
            state.listOrderStatuses = []
        })
        .addCase(callListOrderStatuses.fulfilled, (state, { payload }) => {
            state.listOrderStatuses = payload?.data
        })
        .addCase(callListOrderStatuses.rejected, (state) => {
            state.listOrderStatuses = []
        })
    builder
        .addCase(callGetListCurrencies.pending, (state) => {
            state.listCurrencies = []
        })
        .addCase(callGetListCurrencies.fulfilled, (state, { payload }) => {
            state.listCurrencies = payload?.data
        })
        .addCase(callGetListCurrencies.rejected, (state) => {
            state.listCurrencies = []
        })
    builder
        .addCase(callGetSubOrderStatus.pending, (state) => {
            state.subOrderStatuses = []
        })
        .addCase(callGetSubOrderStatus.fulfilled, (state, { payload }) => {
            state.subOrderStatuses = payload.data
        })
        .addCase(callGetSubOrderStatus.rejected, (state) => {
            state.subOrderStatuses = []
        })

    builder
        .addCase(callGetListSkuComboCategories.pending, (state) => {
            state.listSkuComboCategories = []
        })
        .addCase(callGetListSkuComboCategories.fulfilled, (state, { payload }) => {
            state.listSkuComboCategories = payload.data
        })
        .addCase(callGetListSkuComboCategories.rejected, (state) => {
            state.listSkuComboCategories = []
        })
})
