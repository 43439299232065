import { createAsyncThunk, createReducer, createAction, PayloadAction } from "@reduxjs/toolkit"
import { PAGINATION } from "@config/constant"
import OrderApi from "@api/version2/OrderApi"
import { ICreateOrderRequest, IOrder, IOrderQuery, IOrderStatus } from "@domain/version2/Order"

interface State {
    pagination: {
        currentPage: number
        size: number
        total: number
        totalPage: number
    }
    listOrders: IOrder[]
    loadingList: boolean
    statuses: Array<IOrderStatus>
    loadingExport: boolean
    loadingCreateOrder: boolean
    isShowImport: boolean
    loadingImport: boolean
    dataResponseImport: any
}
const initState: State = {
    pagination: {
        currentPage: PAGINATION.DEFAULT_CURRENT_PAGE,
        size: PAGINATION.DEFAULT_PAGE_SIZE,
        total: PAGINATION.DEFAULT_TOTAL_ITEM,
        totalPage: PAGINATION.DEFAULT_TOTAL_PAGE,
    },
    listOrders: [],
    loadingList: false,
    statuses: [],
    loadingExport: false,
    loadingCreateOrder: false,
    isShowImport: false,
    loadingImport: false,
    dataResponseImport: {},
}

export const changeVisibleOrderImport = createAction<boolean>("ORDER.CHANGE_VISIBLE_IMPORT")

export const callListOrders = createAsyncThunk("ORDER.GET_LIST", async (payload: IOrderQuery, thunkApi) => {
    try {
        return await OrderApi.getListOrders(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const fetchOrderStatuses = createAsyncThunk("ORDER.FETCH_ORDER_STATUS", async (_, thunkApi) => {
    try {
        return await OrderApi.getOrderStatuses()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const exportOrders = createAsyncThunk("ORDER.EXPORT", async (payload: IOrderQuery, thunkApi) => {
    try {
        return await OrderApi.exportOrders(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callCreateOrder = createAsyncThunk("ORDER.CREATE", async (body: ICreateOrderRequest, thunkApi) => {
    try {
        return await OrderApi.createOrder(body)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callImportOrderFinancialStatus= createAsyncThunk("ORDER.IMPORT_FINANCIAL_STATUS", async (payload: { file: any }, thunkApi) => {
    try {
        return await OrderApi.importOrderFinancialStatus(payload.file)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})


export const orderReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callListOrders.pending, (state) => {
            state.loadingList = true
            state.listOrders = []
        })
        .addCase(callListOrders.fulfilled, (state, { payload }) => {
            state.loadingList = false
            state.listOrders = payload?.data || []
            state.pagination.currentPage = Number(payload?.headers["x-page-number"]) || PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.size = Number(payload?.headers["x-page-size"]) || PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = Number(payload?.headers["x-total-count"]) || PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = Number(payload?.headers["x-page-count"]) || PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })
        .addCase(callListOrders.rejected, (state) => {
            state.loadingList = false
            state.listOrders = []
        })
    builder
        .addCase(fetchOrderStatuses.pending, (state) => {
            state.statuses = []
        })
        .addCase(fetchOrderStatuses.rejected, (state) => {
            state.statuses = []
        })
        .addCase(fetchOrderStatuses.fulfilled, (state, action) => {
            state.statuses = action.payload.data
        })
    builder
        .addCase(exportOrders.pending, (state) => {
            state.loadingExport = true
        })
        .addCase(exportOrders.fulfilled, (state) => {
            state.loadingExport = false
        })
        .addCase(exportOrders.rejected, (state) => {
            state.loadingExport = false
        })

    builder
        .addCase(callCreateOrder.pending, (state) => {
            state.loadingCreateOrder = true
        })
        .addCase(callCreateOrder.fulfilled, (state) => {
            state.loadingCreateOrder = false
        })
        .addCase(callCreateOrder.rejected, (state) => {
            state.loadingCreateOrder = false
        })
    builder
        .addCase(changeVisibleOrderImport, (state, action: PayloadAction<boolean>) => {
            state.isShowImport = action.payload
        })
    builder
        .addCase(callImportOrderFinancialStatus.pending, (state) => {
            state.loadingImport = true
            state.dataResponseImport = {}
        })
        .addCase(callImportOrderFinancialStatus.fulfilled, (state, { payload }) => {
            state.loadingImport = false
            state.dataResponseImport = payload.data
        })
        .addCase(callImportOrderFinancialStatus.rejected, (state) => {
            state.loadingImport = false
            state.dataResponseImport = {}
        })
})
