import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { trans } from "@resources/localization"
import { Card, Col, Form, Input, Row, Select, Empty } from "antd"
import { isEmpty } from "lodash"
import { COUNTRY_CODE } from "@config/constant"
import React from "react"
import { numberParser } from "@util/Common"

interface IProps {
    formRef: any
}

export const Customer = (props: IProps) => {
    const dispatch = useAppDispatch()
    const countryCode = useAppSelector((state) => state.vendorDetail.detailVendor.countryCode)
    const provinces = useAppSelector((state) => state.countriesVersion2.provincesSuggest)
    const districts = useAppSelector((state) => state.countriesVersion2?.districtsSuggest)
    const areas = useAppSelector((state) => state.countriesVersion2?.areasSuggest)
    const cities = useAppSelector((state) => state.countriesVersion2.citiesSuggest)

    return (
        <div className="mt-3">
            <Card title={trans("order_detail.contact")}>
                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="customerName"
                            label={trans("order_detail.fullname")}
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <Input
                                placeholder={trans("products.enter_name")}
                                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                onChange={(e: any) => {
                                    const customerName: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "")
                                    props.formRef.setFieldsValue({
                                        customerName,
                                    })
                                }}
                                onBlur={(e) => {
                                    props.formRef.setFieldsValue({
                                        customerName: e.target.value.trim(),
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="customerPhone"
                            label={trans("order_detail.phone")}
                            rules={[{ required: true, message: trans("message.required") }]}>
                            <Input
                                placeholder={trans("order.enter_phone")}
                                onChange={(e: any) => {
                                    const customerPhone: string = e.target.value?.replace(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, "").replace(/[a-zA-Z]/g, "")
                                    props.formRef.setFieldsValue({
                                        customerPhone,
                                    })
                                }}
                                onBlur={(e) => {
                                    props.formRef.setFieldsValue({
                                        customerPhone: e.target.value.trim(),
                                    })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("order.postal_code")}
                            name="postalCode"
                            rules={
                                COUNTRY_CODE.MALAYSIA === countryCode
                                    ? [
                                          {
                                              required: true,
                                              message: trans("message.required"),
                                          },
                                      ]
                                    : []
                            }>
                            <Input
                                onChange={(e) => {
                                    props.formRef.setFieldsValue({
                                        postalCode: e?.target?.value ? numberParser(e?.target?.value) : "",
                                    })
                                }}
                                maxLength={countryCode === "MYS" ? 5 : 20}
                                placeholder={trans("order.enter_postal_code")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={trans("contact-detail.province")}
                            name="provinceCode"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Select
                                placeholder={trans("order_list.district_placeholder")}
                                showSearch
                                getPopupContainer={(trigger) => trigger.parentNode}
                                optionFilterProp="children"
                                notFoundContent={<Empty />}
                                filterOption={(input: any, option: any) => {
                                    return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                }}
                                filterSort={(optionA: any, optionB: any) => {
                                    return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                }}
                                onChange={(e) => {
                                    dispatch(resetDataArea([]))
                                    dispatch(resetDataDistrict([]))
                                    dispatch(resetDataCity([]))
                                    props.formRef.setFieldsValue({
                                        cityCode: "",
                                        districtCode: "",
                                        wardCode: "",
                                    })
                                    if (e) {
                                        dispatch(getCitySuggest(e))
                                    }
                                }}>
                                {provinces?.map((item) => (
                                    <Select.Option
                                        key={item?.id}
                                        value={item?.code}>
                                        {item?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(pre, next) => pre.provinceCode !== next.provinceCode}>
                            {({ getFieldValue }) => (
                                <Form.Item
                                    label={countryCode === COUNTRY_CODE.VIETNAM ? trans("contact-detail.district") : trans("order_list.district")}
                                    name="cityCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: trans("message.required"),
                                        },
                                    ]}>
                                    <Select
                                        placeholder={
                                            countryCode === COUNTRY_CODE.VIETNAM ? trans("contact-detail.district_pl") : trans("order_list.city_placeholder")
                                        }
                                        showSearch
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        notFoundContent={<Empty />}
                                        disabled={!getFieldValue("provinceCode")}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) => {
                                            return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                        }}
                                        filterSort={(optionA: any, optionB: any) => {
                                            return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                        }}
                                        onChange={(e) => {
                                            dispatch(resetDataArea([]))
                                            dispatch(resetDataDistrict([]))
                                            props.formRef.setFieldsValue({
                                                districtCode: "",
                                                wardCode: "",
                                            })
                                            if (e) {
                                                dispatch(getDistrictSuggest(e))
                                            }
                                        }}>
                                        {cities?.map((item) => (
                                            <Select.Option
                                                key={item?.id}
                                                value={item?.code}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                    {!isEmpty(districts) && (
                        <Col span={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(pre, next) => pre.cityCode !== next.cityCode}>
                                {({ getFieldValue }) => (
                                    <Form.Item
                                        label={countryCode === COUNTRY_CODE.VIETNAM ? trans("contact_activity.ward") : trans("contact-detail.district")}
                                        name="districtCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: trans("message.required"),
                                            },
                                        ]}>
                                        <Select
                                            placeholder={
                                                countryCode === COUNTRY_CODE.VIETNAM
                                                    ? trans("order_list.ward_placeholder")
                                                    : trans("contact-detail.district_pl")
                                            }
                                            showSearch
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            notFoundContent={<Empty />}
                                            disabled={!getFieldValue("cityCode")}
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                            }}
                                            filterSort={(optionA: any, optionB: any) => {
                                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                            }}
                                            onChange={(e) => {
                                                dispatch(resetDataArea([]))
                                                props.formRef.setFieldsValue({
                                                    wardCode: "",
                                                })
                                                if (e) {
                                                    dispatch(getAreaSuggest(e))
                                                }
                                            }}>
                                            {districts?.map((item) => (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    )}
                    {!isEmpty(areas) ? (
                        <Col span={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(pre, next) => pre.districtCode !== next.districtCode}>
                                {({ getFieldValue }) => (
                                    <Form.Item
                                        label={trans("contact-detail.area")}
                                        name="wardCode">
                                        <Select
                                            placeholder={trans("placeholder.select_area")}
                                            showSearch
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            disabled={!getFieldValue("districtCode") && !getFieldValue("cityCode")}
                                            optionFilterProp="children"
                                            notFoundContent={<Empty />}
                                            filterOption={(input: any, option: any) => {
                                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                            }}
                                            filterSort={(optionA: any, optionB: any) => {
                                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                            }}>
                                            {areas?.map((item) => (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={24}>
                            <Form.Item
                                label={trans("contact-detail.area")}
                                name="wardCode">
                                <Input
                                    placeholder={trans("contact-detail.area")}
                                    onBlur={(e) => {
                                        props.formRef.setFieldsValue({
                                            wardCode: e.target.value.trim(),
                                        })
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form.Item
                            label={trans("contact-detail.address_detail")}
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: trans("message.required"),
                                },
                            ]}>
                            <Input placeholder={trans("order.enter_address")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="note"
                            label={trans("contact-detail.note")}>
                            <Input.TextArea
                                placeholder={trans("contact-detail.status_pl")}
                                autoSize={{
                                    minRows: 4,
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
