import DefaultLayout from "@component/Layout/Default"
import { HeaderAntBreadCrumb } from "@component/Layout/HeaderAntBreadCrumb"
import { useAppDispatch } from "@hook/useAppDispatch"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "antd"
import { HOME_BREADCRUMB } from "@config/constant"
import { changePageTitleAction } from "@reducer/common.reducer"
import { callGetPackageDetail, callUpdatePackage } from "@reducer/version2/package-detail.reducer"
import { PackageInfo } from "./PackageInfo"
import { TableProduct } from "./TableProduct"
import { useNavigate, useParams } from "react-router-dom"
import { AccessDenied } from "@component/AccessDenied"
import { Notfound } from "@component/Notfound"
import SecurityService from "@util/SecurityService"
import { PACKAGE_SCOPE } from "@config/permission"

export const DetailPackage: React.FC = () => {
    const dispatch = useAppDispatch()
    const id = useParams().id as string
    const [statusShow, setStatusShow] = useState<number>(0)
    const packageDetail = useAppSelector((state) => state.packageDetail.dataDetail)
    const navigate = useNavigate()
    const loadingUpdatePackage = useAppSelector((state) => state.packageDetail.loadingUpdatePackage)

    useEffect(() => {
        dispatch(changePageTitleAction(trans("sidebar.contact_detail")))
        dispatch(callGetPackageDetail(id)).then((result: any) => {
            setStatusShow(result?.payload?.response?.status)
        })
    }, [dispatch])

    const showConfirmDelete = () => {
        Modal.confirm({
            title: trans("message.confirm_cancel"),
            icon: <i className="fa-regular fa-circle-info"></i>,
            onOk() {
                dispatch(callUpdatePackage(packageDetail?.id)).then(() => {
                    dispatch(callGetPackageDetail(String(packageDetail?.id)))
                })
            },
        })
    }

    return (
        <DefaultLayout loading={false}>
            {statusShow === 403 && <AccessDenied />}
            {statusShow === 404 && <Notfound />}
            {![403, 404].includes(statusShow) && (
                <>
                    <HeaderAntBreadCrumb
                        breadCrumbs={[
                            ...HOME_BREADCRUMB,
                            {
                                name: "package.title",
                                link: "/package",
                            },
                            {
                                name: packageDetail?.code || "",
                                link: "",
                            },
                        ]}
                    />
                    <div className="space-x">
                        <Row
                            gutter={12}
                            className="mt-3">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}>
                                <PackageInfo />
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}>
                                <TableProduct />
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-3 mx-3">
                    <Row
                        justify="end"
                        className="mt-3">
                        {packageDetail?.status === "INIT" && SecurityService.can(PACKAGE_SCOPE.PACKAGE_CANCEL) && (
                            <Button
                                loading={loadingUpdatePackage}
                                type="default"
                                onClick={() => showConfirmDelete()}>
                                {trans("package.cancel")}
                            </Button>
                        )}
                        {SecurityService.can(PACKAGE_SCOPE.PACKAGE_CREATE) && (
                            <Button
                                type="primary"
                                className="ml-3"
                                onClick={() => navigate(`/package/create?id=${id}`)}>
                                {trans("package.copy")}
                            </Button>
                        )}
                    </Row>
</div>
                </>
            )}
        </DefaultLayout>
    )
}
