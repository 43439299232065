import { Inventory, InventoryQuery, IWarehouse } from "@domain/version2/InventoryApi"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export class InventoryApi {
    static getInventories(params: InventoryQuery): Promise<AxiosResponse<Inventory[]>> {
        return apiClient.get(`inventory/products`, {
            params,
        })
    }
    static getWarehouses(params: { countryCode?: string }): Promise<AxiosResponse<IWarehouse[]>> {
        return apiClient.get(`inventory/warehouses`, {
            params,
        })
    }
}
